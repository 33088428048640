import React from "react"
import Layout from "../../../components/sermonLayoutElMirage"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/FSTYmwDigJM">
    <SEO title="Prophecy, Praise, and Passion! - The Week That Changed the World" />
  </Layout>
)

export default SermonPost
